<template>
    <div class="grid">
		<div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<Button label="立即创建角色" icon="iconfont icon-jiaose-" 
                            class="mr-2" @click="MakeTeam" 
                        ></Button>
                       
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
        <div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            可用人物卡
                        </span>
						<div class="text-green-500 font-medium text-xl">152</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
        <div class="col-12 lg:col-6 xl:col-4">
			<div class="card mb-0 text-center pt-6 pb-6 countCard">
				<div class="flex justify-content-center ">
					<div>
						<span class="block text-500 font-medium mb-3">
                            武器
                        </span>
						<div class="text-pink-500 font-medium text-xl">152</div>
					</div>
					
				</div>
				<!-- <span class="text-green-500 font-medium">24 new </span>
				<span class="text-500">since last visit</span> -->
			</div>
		</div>
    </div>
	
    <div class="content_title mt-4 mb-4">
        我的战队
    </div>
	<div class="grid ">
		<div class="col-12">
			 <Button label="刷新我的武器列表" icon="iconfont icon-jiaose-" 
    			class="mr-2" @click="getArmsListEvent()"></Button>
		</div>
	</div>
	<div class="grid ">
			<div class="col-12 lg:col-6 xl:col-3" v-for="item in ArmList" v-if="ArmList">
				<div class="card mb-0 text-center imgCard text-left">
					<img src="@/assets/img/monkey.jpg" />
					<div class="content">
						<h5 class="title">孙悟空</h5>
						<p>UID：099900</p>
						<p>等级：1</p>
						<p>培养费：1000</p>
					</div>
				</div>
			</div>
			
		</div>
</template>


<script>
import { defineComponent,ref,store,computed} from 'vue';
 import {
        useRouter
    } from "vue-router";
import createArms from "../../components/Create/arms.vue";
import {getArmsList} from "../../wallet/LogicArms";
export default defineComponent({
    name:"armsPage",
	
 	components: {
		createArms
	},
	setup(){
        const router=useRouter()
        const MakeTeam=()=>{
             router.push("/battle/make")
        }
		let ArmList=ref('');
		const getArmsListEvent=()=>{
			ArmList.value='';
			getArmsList().then(res=>{
				console.log("获取地址下的所有武器",res)
				ArmList.value=res;
				
			}).catch(error=>{
				console.log("调用方法失败")
			})
		}
		getArmsListEvent();
		return{
            MakeTeam,

			ArmList,
			getArmsListEvent
		}
	}
	
})
</script>
